
// Custom bootstrap values
$primary: #A3A375;
$min-contrast-ratio:   1 !default;

// ... fonts
$headings-font-family: "Sacramento";
$font-family-base: "Playwrite CL", cursive;
$font-weight-normal: 300;
$input-font-family: "Roboto";

@import "bootstrap";

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;


  
  line-height: 2.45rem;
  font-size: 1.2rem;
}

h1 {
  text-align: center;
  font-size: 4rem;
  margin: 4rem;
}


@media (max-width: 992px) { 
  h1 {
    margin: 2rem;
  }
}

h2 {
  text-align: center;
}
.section {
  padding: 6rem 0;
  background-color: #f7f6f4;
}


@media (max-width: 992px) { 
  .section {
    padding: 3rem 0;
  }
}

.section-white {
  background-color: white;
}


ul.list li {
  list-style-type: "~ ";
}


@media (max-width: 768px) {
  ul.list.accom-list {
    padding-left: 0; 
    
    li {
      list-style-type: " ";
      padding: 1rem 0;
    }
    li:not(:last-child) {
      border-bottom: 1px solid #A3A37544;
    }
  }
}

.modal h1 {
  margin: 1rem;
}

.modal h2 {
  text-align: left;
}

.modal h2.guest-name {
  text-decoration: underline;
}
.modal .form-label {
  font-family: "Playwrite CL", cursive;
}

.btn {
  font-family: "Playwrite CL", cursive;
  line-height: 2em;
}

.nav-link {
  font-family: "Playwrite CL", cursive;
  font-weight: bold;
  line-height: 2em;
}

.nav-link:last-of-type {
  border-bottom: none;
}

@media (max-width: 992px) { 
  .nav-link {
    border-bottom: 1px solid #888888;
    padding-top: 1em;
  }
}
@media (min-width: 992px) {
  .nav-link {
    margin-right: 2rem;
  }
}

.navbar .btn-secondary {
  height: 3em;
}


.modal .alert {
  font-weight: 300;
  font-size: 1rem;
  padding: 0 1rem;
}