.hero {
  background-image: url("./static//background.jpg");
  background-size: cover;
  background-position: 50% 50%;
  filter: grayscale(100%);
  padding: 0;
  
  min-height: 100vh;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative
}

.menu {
  position: fixed;
  top: 0;
}

.logo {
  background-image: url("./static//wl-white.png");
  min-height: 100px;
  min-width: 50vw;
  margin: 0;
  width: 50vw;
  height: auto;
  max-height: 50vh;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  aspect-ratio: 1;
}
:target::before {
  content: '';
  display: block;
  height: 150px;
  margin-top: -150px;
}

h1 {
  font-weight: 100;
  margin-left: 0;
  margin-right: 0;
}

h2 {
  font-family: "Playwrite CL", cursive;
  font-size: 1.4rem;
  line-height: 3rem;
  margin-left: 0;
  margin-right: 0;
}
.active {
  color: #A3A375;
}

.venueImage {
  background-image: url("./static//tcb.png");
  min-width: 100%;
  min-height: 20rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  height: 100%;
}
@media (max-width: 768px) { 
  .venueImage {
    min-height: 10rem;
  }
}
.boquetImage {
  background-image: url("./static//boquet.png");
  min-width: 100%;
  min-height: 20rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  height: 100%;
}
.giftImage {
  background-image: url("./static//gifts.jpg");
  min-width: 100%;
  min-height: 15rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  height: 100%;
}
.accomImage {
  background-image: url("./static//accom.jpg");
  min-width: 100%;
  min-height: 15rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  height: 100%;
}
.attireImage {
  background-image: url("./static//attire.png");
  min-width: 100%;
  min-height: 15rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  height: 100%;
}
.foodImage {
  background-image: url("./static//food.jpg");
  min-width: 100%;
  min-height: 15rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  height: 100%;
}
@media (max-width: 768px) { 
  .giftImage {
    min-height: 10rem;
  }
}
.schedule {
  text-align: center;
}
.schedule-item {
  font-family: "Playwrite CL", cursive;
}
.schedule-divider {
  display: inline-block;
  width: 100px;
  height: 1rem;
  margin: 1rem 0;
  border-bottom: 1px solid lightgray;
}

.time {
  font-size: 1.2rem;
  padding-right: 1rem;
  color: #555555;
  font-weight: 800;
}

.event-title {
  font-weight: 500;
  font-size: 1.2rem;
  padding-right: 1rem;
  color: #555555;
}

.section.at-a-glance {
  min-height: 100vh;
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}



.question-divider {
  width: 100%;
  height: 1rem;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid lightgray;
}


@media (max-width: 768px) { 
  .address-component {
    display: block;
  }
}
@media (min-width: 768px) {
  .address-component:not(:last-child)::after {
    content: "|";
  }
}

